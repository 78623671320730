.hero {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding-block: 80px;
}
.sliderDiv {
  width: 100%;
}
.sliderDiv .splide__slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 1320px;
  gap: 30px;
}
.leftSlide {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
  color: white;
  max-width: 630px;
}
.leftSlide h4,
.leftSlide p {
  font-size: 18px;
  font-weight: 400;
}
.leftSlide h4 {
  text-transform: uppercase;
}
.leftSlide h3 {
  font-weight: 400;
  line-height: 1.3;
  font-size: 52px;
}
.slide img {
  max-width: 612px;
  object-fit: contain;
  width: 100%;
}
.stepWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  align-items: flex-start;
  isolation: isolate;
  position: relative;
  margin-top: -20px;
}
.stepWrapper::before {
  content: "";
  width: 70%;
  z-index: -1;
  position: absolute;
  top: 1.5rem;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  border-top: 4px var(--primary) dotted;
}
.step {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  text-align: center;
  color: white;
  flex-direction: column;
  max-width: 272px;
}
.step .dot {
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(90, 174, 45, 0.5);
  border-radius: 50%;
}
.step .dot::before {
  content: "";
  width: 16px;
  height: 16px;
  background-color: var(--primary);
  border-radius: 50%;
}
.step h4 {
  font-size: 23px;
  line-height: 1;
  margin-bottom: -4px;
  font-weight: 500;
}
.step p {
  font-size: 17px;
}

.ctaInverBtn {
  font-family: "Nunito";
  transition: all 0.5s ease-in-out;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  font-size: 1rem;
  border-radius: 2px;
  padding: 16px 48px;
}

.ctaInverBtn {
  color: rgb(82, 185, 110);
  background-color: rgba(52, 173, 84, 0);
  border-color: rgb(90, 174, 45);
  border: 1px solid;
}
.ctaInverBtn:hover {
  box-shadow: inset 0 0 0 0 var(--secondary);
  color: rgb(0, 0, 0);
  background-color: rgb(82, 185, 110);
  border-color: rgb(72, 181, 101);
}

@media screen and (max-width: 1413px) {
  .leftSlide {
    gap: 14px;
  }
  .leftSlide h3 {
    font-size: 40px;
  }
  .leftSlide p {
    margin-bottom: 10px;
  }
  .slide img {
    max-width: 460px;
  }
}
@media screen and (max-width: 1083px) {
  .hero {
    padding-top: 150px;
  }
  .slide {
    gap: 10px;
    flex-direction: column;
  }
  .slide img {
    max-width: 600px;
    margin-top: -50px;
  }
  .leftSlide {
    max-width: 100%;
  }
  .stepWrapper {
    margin-top: -80px;
  }
}
@media screen and (max-width: 750px) {
  .stepWrapper {
    gap: 50px;
    grid-template-columns: 1fr;
  }
  .stepWrapper::before {
    display: none;
  }
}
@media screen and (max-width: 563px) {
  .hero {
    padding-top: 140px;
  }
  .leftSlide h3 {
    font-size: 30px;
  }
  .leftSlide h4,
  .leftSlide p {
    font-size: 18px;
  }
  .leftSlide p {
    line-height: 1.5;
  }
  .slide img {
    margin-top: -20px;
  }
  .stepWrapper {
    margin-top: -30px;
  }
}
