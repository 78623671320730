.titleWrapp {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  max-width: 900px;
  text-align: center;
}
.titleWrapp h4 {
  color: var(--primary);
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 500;
}
.titleWrapp h2 {
  font-size: 2.25rem;
  font-weight: 400;
}
.titleWrapp .line {
  background-color: var(--primary);
  width: 150px;
  height: 5px;
  border-radius: 100vh;
  position: relative;
  overflow: hidden;
}
.titleWrapp .line:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 5px;
  background-color: white;
  left: 0;
  top: 0;
  animation: moving 5s infinite linear alternate;
}
@keyframes moving {
  from {
    left: 0;
  }
  to {
    left: 75%;
  }
}
@media screen and (max-width: 900px) {
  .titleWrapp h2 {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 620px) {
  .titleWrapp h2 {
    font-size: 36px;
  }
  .titleWrapp h4 {
    font-size: 17px;
  }
}
