.deposit2-page {
  padding: 40px 0;
  width: 100%;
  height: 100%;
  min-height: 730px;
  .wrap {
    gap: 40px;
    @include media("<=desktop") {
      gap: 20px;
    }
    @include media("<=tablet") {
      flex-direction: column;
    }
    .page-header {
      margin-bottom: 40px;
      @include media("<=phoneH") {
        margin-bottom: 20px;
      }
      .page-title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
      .question-icon {
        height: 40px;
        width: 40px;
        padding: 4px;
        border-radius: 12px;
        cursor: pointer;
        @include anim;
        border: 1px solid transparent;
        &:hover {
          border-color: $themeColor;
          svg {
            path {
              fill: $themeColor;
            }
          }
        }
      }
    }
    .tips-section {
      .sec-tag {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 12px;
      }
      .tip-box {
        padding: 30px 20px;
        border-radius: 20px;
        @include media("<=phoneH") {
          padding: 20px 10px;
        }
        .tip-item {
          margin-bottom: 24px;
          @include media("<=phoneH") {
            margin-bottom: 15px;
          }
          &:last-child {
            margin-bottom: 0;
          }
          .dot {
            height: 8px;
            width: 8px;
            border-radius: 50%;
            background: $themeColor;
            margin-right: 12px;
            margin-top: 4px;
            @include media("<=phoneH") {
              margin-right: 6px;
              height: 4px;
              width: 4px;
            }
          }
          .desc {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #a4a8ab;
          }
        }
      }
    }
    .page-left {
      flex: 1;
      padding: 20px;
      @include media("<=desktop") {
        padding: 10px;
      }

      .coin-selection {
        margin: 30px 0;
        .sec-title {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #a4a8ab;
          margin-bottom: 30px;
        }
        .selection-lbl {
          color: #a4a8ab;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 12px;
        }
        .coin-field {
          padding: 6px 8px;
          border-radius: 20px;
          @include media("<=phoneH") {
            flex-direction: column;
            align-items: start;
          }
          .token-info {
            padding: 6px;
            border-radius: 10px;
            margin-right: 12px;
            .icon {
              margin-right: 5px;
              .img {
                height: 24px;
                width: 24px;
                border-radius: 50%;
              }
            }
            .token-name {
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #ffffff;
              margin-left: 8px;
            }
          }
          .transfer-amount {
            @include media("<=phoneH") {
              margin: 12px 0;
            }
            .lbl {
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 21px;
              color: rgba(255, 255, 255, 0.5);
            }
            .val {
              margin-left: 8px;
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: rgba(255, 255, 255, 0.95);
            }
          }
          .token-actions {
            gap: 12px;
            .btn-copy {
              width: 86px;
              padding: 8px 14px;
              border-radius: 14px;
              display: flex !important;
              align-items: center;
              justify-content: center;
              .btn-lbl {
                margin-right: 10px;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
              }
              .btn-icon {
                svg {
                  height: 18px;
                  width: 18px;
                }
              }
              &:nth-child(2) {
                border-color: rgba(12, 175, 96, 0.2);
                background: rgba(12, 175, 96, 0.2);
              }
            }
          }
        }
      }

      .address-sec {
        margin: 30px 0;
        .selection-lbl {
          color: #a4a8ab;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 12px;
        }
        .address-box {
          padding: 6px 8px;
          border-radius: 20px;
          .a-code {
            width: 100%;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #ffffff;
            @include media("<=phoneH") {
              font-size: 10px;
              line-height: 19px;
            }
          }
          .btn-copy {
            padding: 5px 14px;
            border-radius: 14px;
            display: flex !important;
            align-items: center;
            .btn-lbl {
              margin-right: 10px;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 24px;
            }
            .btn-icon {
              svg {
                height: 18px;
                width: 18px;
              }
            }
          }
        }
      }
      .btns-actions {
        margin-bottom: 30px;
        ._btn {
          font-weight: 300;
          border-radius: 16px;
          &:nth-child(2) {
            border-color: #50565c;
            background: #50565c;
          }
        }
      }
      .tips-section {
        @include media("<=tablet") {
          display: none;
        }
      }
    }
    .page-right {
      flex: 0.5;
      padding: 20px;
      @include media("<=desktop") {
        padding: 10px;
      }
      .sec-title {
        .tag {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #ffffff;
          margin-right: 8px;
        }
        .icon {
          svg {
            height: 20px;
            width: 20px;
          }
        }
      }
      .tabs-sec {
        margin: 30px 0;
        @include media("<=phoneH") {
          margin: 20px 0;
        }
        .tabs-box {
          width: 100%;
          border-radius: 16px;
          padding: 4px;
          border: 1px solid #212a36;
          backdrop-filter: blur(21px);
          .btn-tab {
            flex: 1;
            border-radius: 14px;
            border: none;
            padding: 10px 5px;
            background: transparent;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #a4a8ab;
            &.active {
              color: #ffffff;
              background: #212a36;
            }
            @include media("<=phoneH") {
              padding: 8px 4px;
              font-size: 10px;
            }
          }
        }
      }
      .avg-time {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #a4a8ab;
        .clock-icon {
          margin-right: 12px;
        }
        .time {
          margin-left: 10px;
          color: #ffffff;
        }
      }
      .qrcode-sec {
        margin-bottom: 100px;
        @include media("<=phoneH") {
          margin-bottom: 40px;
        }
        .tag {
          font-weight: 500;
          font-size: 16px;
          line-height: 27px;
          color: #ffffff;
          text-align: center;
          margin: 30px 0;
        }
        .qr-code {
          height: 220px;
          width: 220px;
          object-fit: contain;
        }
      }
      .description-sec {
        .warn-icon {
        }
        .sec-tag {
          font-weight: 500;
          font-size: 16px;
          line-height: 27px;
          color: #ffffff;
          text-align: center;
          margin: 10px 0;
        }
        .desc {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #a4a8ab;
        }
      }
      .tips-section {
        margin-top: 30px;
        display: none;
        @include media("<=tablet") {
          display: flex;
        }
      }
    }
  }
}
