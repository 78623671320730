.choose {
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 100px 0px;
  padding-top: 50px;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  width: 100%;
  margin-top: 80px;
  row-gap: 70px;
  column-gap: 40px;
}
.grid > .lottie {
  grid-column: 2/3;
  grid-row: 1/3;
  object-fit: contain;
  height: 350px;
  align-self: center;
  justify-self: center;
}
.box {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
}
.box .icoWrapp {
  background: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 5px;
}
.icoWrapp svg {
  color: white;
  font-size: 16px;
}
.box h4 {
  font-weight: 400;
  color: white;
  font-size: 1.5rem;
}
.box p {
  color: rgb(182, 182, 182);
  font-size: 17px;
  line-height: 1.6;
}
@media screen and (max-width: 900px) {
  .choose {
    padding-bottom: 60px;
  }
  .grid {
    grid-template-columns: 1fr 1fr;
    margin-top: 50px;
    grid-template-rows: 1fr auto auto;
  }
  .grid > .lottie {
    margin-bottom: -40px;
    grid-row: 1/2;
    grid-column: 1/3;
  }
}
@media screen and (max-width: 580px) {
  .choose {
    padding-top: 40px;
  }
  .grid {
    grid-template-rows: 1fr auto auto auto auto;
    grid-template-columns: 1fr;
    row-gap: 40px;
  }
  .grid > .lottie {
    margin-bottom: -20px;
    grid-column: 1/2;
    grid-row: 3/4;
  }
}
