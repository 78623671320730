.products {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0px;
}
.productGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  width: 100%;
  gap: 40px;
  margin-top: 50px;
}
.productCard,
.lastBox {
  background: #fff;
  border: 1px solid #8d8a8a;
  position: relative;
  min-height: 340px;
  padding: 20px 30px;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  text-align: center;
  flex-direction: column;
  border-radius: 10px;
}
.lastBox {
  background-color: var(--primary);
  border-color: var(--primary);
}
.lastBox h4 {
  font-size: 1.75rem;
  color: white;
  font-weight: 400;
}
.lastBox p {
  font-size: 17px;
  color: white;
  font-weight: 400;
}
.lastBox button {
  padding: 1rem 3rem;
  border: 1px solid #eef9ff;
  color: #eef9ff;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  transition: 0.5s;
  background-color: transparent;
  font-size: 16px;
  border-radius: 2px;
}
.lastBox button:hover {
  background-color: #eef9ff;
  color: #011150;
}
.productCard .icoWrapp {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  border-radius: 2px;
  transform: rotate(-45deg);
  margin-bottom: 15px;
}
.icoWrapp img {
  max-width: 30px;
  object-fit: contain;
}
.productCard h4 {
  color: #011150;
  font-size: 2.25rem;
  line-height: 1;
  font-weight: 400;
}
.productCard p {
  font-size: 17px;
  color: #6b6a75;
}

.ctaBtn {
  font-family: "Nunito";
  transition: all 0.5s ease-in-out;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  font-size: 1rem;
  border-radius: 2px;
  padding: 0.5rem 1.5rem;
}
.ctaBtn {
  background-color: var(--primary);
  color: white;
  border: 1px solid var(--primary);
}
.ctaBtn:hover {
  box-shadow: inset 0 0 0 0 var(--primary);
  color: rgb(0, 0, 0);
  background-color: rgb(82, 185, 110);
  border-color: rgb(82, 185, 110);
}
