.lading-page {
  padding: 40px 0;
  width: 100%;
  height: 100%;
  min-height: 730px;
  .wrap {
    gap: 30px;
    .page-top-sec {
      gap: 30px;
      height: 500px;
      @include media("<=desktop") {
        flex-direction: column;
        height: 100%;
      }
      .top-sec-left {
        flex: 1;
        gap: 30px;
        max-width: calc(100% - 325px);
        @include media("<=desktop") {
          max-width: 100%;
        }
        .finance-grids {
          grid-gap: 20px;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          @include media("<=phoneH") {
            grid-gap: 10px;
            grid-template-columns: repeat(2, 1fr);
          }
          .price-box {
            width: 100%;
            height: 105px;
            padding: 12px;
            .amount {
              font-weight: 600;
              font-size: 22px;
              line-height: 39px;
              color: #fff;
              @include media("<=phoneH") {
                font-size: 14px;
              }
            }
            .title-box {
              margin-top: 8px;
              margin-bottom: 6px;
              .dot-box {
                height: 18px;
                width: 18px;
                border-radius: 8px;
                .dot {
                  padding: 4px;
                  border-radius: 50%;
                  background: #fff;
                }
              }
              .lbl {
                margin-left: 8px;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #c2c2c2;
                @include media("<=phoneH") {
                  font-size: 12px;
                }
              }
            }
            .border-box {
              width: 100%;
              height: 3px;
              border-radius: 6px;
            }
          }
        }
        .profile-stats-section {
          @include media("<=phoneH") {
            max-width: 100%;
            overflow: hidden;
          }
          .ps-header {
            margin-bottom: 10px;
            @include media("<=phoneH") {
              align-items: start;
              flex-direction: column;
            }
            .hdr-title {
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #ffffff;
            }
            .times-tabs {
              gap: 8px;
              @include media("<=phoneH") {
                margin: 12px 0;
              }
              .tab {
                font-weight: 400;
                font-size: 10px;
                line-height: 15px;
                color: #ffffff;
                padding: 6px 14px;
                border: 1px solid rgba(128, 129, 149, 0.3);
                border-radius: 10px;
                @include anim;
                &.active {
                  background: $themeColor;
                  border-color: $themeColor;
                }
              }
            }
          }
          .chart-sec {
            svg {
              width: 100%;
            }
            @include media("<=phoneH") {
              svg {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
      .top-sec-right {
        flex: 0.4;
        .sec-title {
          color: #ffffff;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }
        .btc-chars-sec {
          .btc-full-chart {
            margin: 30px 0;
            margin-bottom: 0;
            @include media("<=phoneH") {
              margin: 20px 0;
              margin-bottom: 0;
              max-width: 330px;
              overflow: hidden;
              margin-left: auto;
              margin-right: auto;
            }
            .donut-chat-sec {
              height: 200px;
              width: 100%;

              .chart-inner-val {
                padding: 10px;
                border-radius: 50% 50% 0 0;
                background: #3d3e52;
                .price {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  text-align: center;
                  color: #ffffff;
                }
                .val {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  text-align: center;
                  color: #ffffff;
                }
              }

              @media (max-width: 1024px) and (min-width: 767px) {
                top: -140px;
              }
            }
            .recharts-responsive-container {
              height: 100%;
              width: 100%;
              .recharts-wrapper {
                height: 100%;
                width: 100%;
                svg {
                  height: 85%;
                  width: 100%;
                }
              }
            }
          }
          .separates-btc-charts {
            max-height: 220px;
            overflow: hidden;
            overflow-y: auto;
            .sec-title {
              margin-top: 25px;
              margin-bottom: 10px;
            }
            .wishlist-sec {
              // max-height: 220px;
              // overflow: hidden;
              // overflow-y: auto;
              .card-item {
                padding: 12px 20px;
                height: 80px;
                width: 100%;
                border-radius: 12px;
                margin-bottom: 12px;
                &:last-child {
                  margin-bottom: 0;
                }
                .card-left {
                  flex: 1;
                  height: 100%;
                  .icon {
                    height: 44px;
                    width: 44px;
                    border-radius: 12px;
                    .img {
                      height: 24px;
                      width: 24px;
                    }
                  }
                  .about-token {
                    margin-left: 20px;
                    .name {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 500;
                      font-size: 16px;
                      line-height: 21px;
                      color: #ffffff;
                      margin-bottom: 4px;
                    }
                    .currency {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 500;
                      font-size: 12px;
                      color: #9e9fa4;
                    }
                  }
                }
                .card-right {
                  height: 100%;
                  .amount {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    color: #9e9fa4;
                  }
                  .amountn {
                    margin: auto 0;
                    padding: auto;
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 19px;
                    color: #ffffff;
                  }
                }
              }
            }
            // grid-gap: 25px;
            // display: grid;
            // grid-template-columns: repeat(2, 1fr);
            // .single-btc-chart {
            //   .s-chart {
            //   }
            //   .values {
            //     margin-left: 8px;
            //     .lbl {
            //       font-weight: 400;
            //       font-size: 12px;
            //       line-height: 18px;
            //       color: #c2c2c2;
            //     }
            //     .price {
            //       font-style: normal;
            //       font-weight: 500;
            //       font-size: 14px;
            //       line-height: 21px;
            //       color: #fff;
            //     }
            //   }
            // }
          }
        }
        .see-all {
          cursor: pointer;
          margin-top: 30px;
          border-top: 1px solid rgba(36, 45, 57, 0.6);
          padding: 12px 12px;
          .lbl {
            margin-right: 8px;
          }
          .ico {
          }
        }

        @include media("<=desktop") {
          flex: 1 1;
        }

      }
    }
    .page-center-sec {
      gap: 30px;
      height: 400px;
      margin-top: 80px;
      @include media("<=desktop") {
        flex-direction: column;
        height: 100%;
      }
      @include media("<=tablet") {
        margin-top: 10px;
      }
      .center-sec-left {
        flex: 1;
        gap: 30px;
        .sc-header {
          gap: 12px;
          @include media("<=phoneH") {
            flex-direction: column;
            align-items: start;
          }
          .sec-title {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
          }
          .select {
            min-width: 180px;
            background: #12181f;
            @include media("<=phoneH") {
              width: 100%;
            }
            .css-13cymwt-control,
            .css-t3ipsp-control {
              background: #12181f;
              border-radius: 15px;
              &:hover {
                border-color: $themeColor;
              }
            }
            .css-1u9des2-indicatorSeparator {
              display: none;
            }
            .css-1dimb5e-singleValue {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: #c2c2c2;
            }
            .css-qbdosj-Input {
              input {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #c2c2c2;
              }
            }
            .css-1nmdiq5-menu {
              background: #12181f;
            }
          }
          .find-box {
            // width: 330px;
            border-radius: 14px;
            background: #12181f;
            border: 1.6px solid transparent;
            overflow: hidden;
            @include anim;
            &:focus-within {
              border-color: $themeColor;
            }
            @include media("<=phoneH") {
              width: 100%;
            }
            .search-icon {
              padding: 10px;
            }
            .find-icon {
              cursor: pointer;
              padding: 6px;
              border-radius: 10px;
            }
            .txt {
              margin: 0 10px;
              color: #92929d;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
            }
          }
        }
        .transaction-table {
          overflow: hidden;
          overflow-y: auto;
          @include media("<=phoneH") {
            overflow: auto;
            &::-webkit-scrollbar {
              height: 4px;
            }
          }
          .tran-tbl {
            width: 100%;
            .row {
              gap: 12px;
              padding: 12px 0;
              @include media("<=phoneH") {
                width: 720px;
              }
              .row-item {
                flex: 1;
                .lbl {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                  color: #ffffff;
                }
                .icon {
                  margin-left: 6px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                .coin-img {
                  height: 32px;
                  width: 32px;
                  object-fit: contain;
                  border-radius: 50%;
                }
                .amount {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 24px;
                  color: #ffffff;
                }
                .type {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                  color: #a4a8ab;
                }
                .val {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 21px;
                  color: #ffffff;
                }
                .status-box {
                  padding: 8px 12px;
                  color: #0caf60;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 21px;
                  background: #1f2f2f;
                  border-radius: 12px;
                  &.declined {
                    color: #ff5449;
                    background: #332229;
                  }
                  &.pending {
                    color: #fb774a;
                    background: rgba(247, 147, 26, 0.15);
                  }
                }
                &:first-child {
                  flex: 0.4;
                }
                &:last-child {
                  flex: 0.6;
                }
              }
              &:first-child {
                padding: 10px 0;
                border-bottom: 1px solid rgba(36, 45, 57, 0.6);
                .row-item {
                  &:hover {
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
      .center-sec-right {
        flex: 0.4;
        .scr-header {
          .sec-title {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
          }
          .icons {
            .ico {
              cursor: pointer;
              @include anim;
              svg {
                height: 20px;
                width: 20px;
              }
              &:hover {
                svg {
                  path {
                    fill: $themeColor;
                  }
                }
              }
            }
          }
        }
        .exchange-sec {
          gap: 12px;
          align-items: center;
          justify-content: center;
          margin: 40px 0;
          .exchange-box {
            width: 100%;
            height: 95px;
            padding: 14px;
            background: #12181f;
            border-radius: 20px;
            .up {
              margin-bottom: 16px;
              .up-lbl {
                font-weight: 400;
                font-size: 11px;
                line-height: 20px;
                color: #a4a8ab;
              }
            }
            .btm {
              .dropDown {
                .category {
                  .cbox {
                    border: none;
                    padding: 0 6px;
                    min-width: 110px;
                    .slt {
                      .icon {
                        margin-right: 5px;
                        .img {
                          height: 24px;
                          width: 24px;
                          border-radius: 50%;
                        }
                      }
                      .unit-name {
                        .unit-eng {
                          font-style: normal;
                          font-weight: 600;
                          font-size: 14px;
                          line-height: 24px;
                          color: #ffffff;
                        }
                      }
                    }
                  }
                }
              }
              .btm-val {
                font-weight: 500;
                font-size: 14px;
                line-height: 27px;
                color: #ffffff;
              }
              .input-txt {
                width: 60px;
                font-weight: 500;
                font-size: 14px;
                line-height: 27px;
                color: #ffffff;
                padding: 4px 6px;
                border-radius: 12px;
                border: 1px solid transparent;
                &:focus {
                  border-color: $themeColor;
                }
              }
            }
          }
          .exh-btn {
            height: 36px;
            width: 36px;
            border-radius: 50%;
            border: 4px solid #161d26;
            background: $themeColor;
            cursor: pointer;
          }
        }
        .btn-continue {
          display: flex !important;
          border-radius: 12px;
          .btn-lbl {
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #ffffff;
          }
          .btn-icon {
            padding: 8px;
            border-radius: 12px;
            background: rgba(255, 255, 255, 0.2);
            svg {
              height: 12px;
              width: 12px;
            }
          }
        }
      }
    }
    .page-bottom-sec {
      .pbs-header {
        .sec-title {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }
      }
      .photos-side {
        overflow: hidden;
        .photos-wrapper {
          display: none;
          width: 100%;
          @include media("<=phoneH") {
            display: flex;
          }
          .photos_block {
            width: 100%;
            grid-gap: 12px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            .img-box {
              width: 100%;
              height: 116px;
              background: #23222ca1;
              object-fit: cover;
              background-repeat: no-repeat;
              background-size: cover;
              .img-overlay {
                height: 100%;
                width: 100%;
                cursor: pointer;
                padding: 20px;
                @include media("<=phoneH") {
                  padding: 14px 10px;
                  background: rgba(0, 0, 0, 0.6);
                }
                .i-top {
                  flex: 1;
                  display: none;
                  @include media("<=phoneH") {
                    display: flex;
                  }
                  .img-tag {
                    font-family: "Open Sans";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 22px;
                    text-align: center;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                    color: #fefefe;
                    @include anim;
                    @include media("<=phoneH") {
                      font-weight: 600;
                      font-size: 12px;
                    }
                    &:hover {
                      color: #e10e2f;
                    }
                  }
                }
                .i-btm {
                  display: none;
                  @include media("<=phoneH") {
                    display: flex;
                  }
                  .img-numb {
                    font-family: "Open Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    text-align: center;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                    color: #ffffff;
                    @include media("<=phoneH") {
                      font-size: 10px;
                    }
                  }
                }
                &:hover {
                  background: rgba(0, 0, 0, 0.6);
                  .i-top,
                  .i-btm {
                    display: flex;
                  }
                }
              }
              // .img {
              //   width: 100%;
              //   height: 100%;
              //   object-fit: cover;
              // }
            }
          }
        }
        .swiper-wrapper {
          margin-top: 40px;
        }
        .photos-swiper {
          height: 240px;
          width: 100%;
          margin: 0;
          .slider-card {
            width: 100%;
            height: 160px;
            padding: 12px;
            border-radius: 12px;
            border: 1px solid transparent;
            @include anim;
            &:hover {
              border: 1px solid $themeColor;
            }
            .card-header {
              .token-info {
                .token-img {
                  height: 24px;
                  width: 24px;
                  object-fit: contain;
                }
                .token-data {
                  margin-left: 8px;
                  .token-name {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #ffffff;
                  }
                  .token-sname {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #9a9cb8;
                  }
                }
              }
              .select {
                min-width: 90px;
                background: #12181f;
                z-index: 100;
                .css-13cymwt-control,
                .css-t3ipsp-control {
                  background: #12181f;
                  border-radius: 15px;
                  &:hover {
                    border-color: $themeColor;
                  }
                }
                .css-1u9des2-indicatorSeparator {
                  display: none;
                }
                .css-1dimb5e-singleValue {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 21px;
                  color: #c2c2c2;
                }
                .css-qbdosj-Input {
                  input {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #c2c2c2;
                  }
                }
                .css-1nmdiq5-menu {
                  background: #12181f;
                }
              }
            }
          }
          .swiper-button-prev {
            top: 10px !important;
            width: 100% !important;
            justify-content: end !important;
            left: -35px !important;
            &::after {
              font-size: 18px !important;
              font-weight: bold !important;
              color: #4e4e4e !important;
            }
          }
          .swiper-button-next {
            top: 10px !important;
            justify-content: end;
            right: 0px !important;
            &::after {
              // content: "\ea3c" !important;
              font-size: 18px !important;
              font-weight: bold !important;
              color: #4e4e4e !important;
            }
          }
        }
      }
    }
  }
}
