.contain {
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 999;
  position: fixed;
  background: linear-gradient(
    253deg,
    rgb(1, 17, 80),
    rgb(1, 17, 80),
    rgb(1, 14, 34)
  );
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 15px 3rem;
}
.newBg {
  background: white;
}
.topNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.leftNav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.leftNav img {
  max-width: 130px;
  object-fit: contain;
  filter: brightness(11.3);
}
.newBg .leftNav img {
  filter: none;
}
.leftNav nav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-left: 120px;
}
.leftNav nav a,
.mobNav a {
  color: white;
  font-size: 19px;
  font-weight: 300;
  position: relative;
  padding-bottom: 10px;
}
.leftNav nav .activeLink,
.mobNav .activeLink {
  color: var(--primary);
}
.leftNav nav .activeLink::before,
.mobNav .activeLink::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--primary);
}
.newBg .leftNav nav .activeLink,
.newBg .mobNav .activeLink {
  color: var(--primary);
}
.rightNav,
.mobNav .mobBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.menuToggler {
  display: none;
}
.newBg .leftNav nav a,
.newBg .mobNav a {
  color: black;
}
.mobNav {
  display: none;
}
.ctaBtn,
.ctaInverBtn {
  font-family: "Nunito";
  transition: all 0.5s ease-in-out;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  font-size: 1rem;
  border-radius: 2px;
  padding: 8px 24px;
}
.ctaBtn {
  background-color: var(--primary);
  color: white;
  border: 1px solid var(--primary);
}
.ctaBtn:hover {
  box-shadow: inset 0 0 0 0 var(--primary);
  color: rgb(0, 0, 0);
  background-color: rgb(82, 185, 110);
  border-color: rgb(82, 185, 110);
}
.ctaInverBtn {
  color: rgb(82, 185, 110);
  background-color: rgba(52, 173, 84, 0);
  border-color: rgb(90, 174, 45);
  border: 1px solid;
}
.ctaInverBtn:hover {
  box-shadow: inset 0 0 0 0 var(--secondary);
  color: rgb(0, 0, 0);
  background-color: rgb(82, 185, 110);
  border-color: rgb(72, 181, 101);
}

@media screen and (max-width: 910px) {
  .leftNav nav {
    margin-left: 40px;
  }
}
@media screen and (max-width: 700px) {
  .contain {
    padding: 15px 20px;
    overflow: hidden;
  }
  .leftNav nav {
    display: none;
  }
  .rightNav {
    display: none;
  }
  .menuToggler {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 43px;
    border: 1px solid var(--primary);
    padding: 6px;
    color: var(--primary);
    background-color: transparent;
  }
  .menuToggler svg {
    font-size: 32px;
    font-weight: 900;
  }
  .mobNav {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
  }
  .mobNav a {
    width: max-content;
  }
  .mobNav .mobBtn {
    width: max-content;
  }
}
