.reviews {
  flex-direction: column;
  gap: 0px;
  padding-top: 80px;
  padding-bottom: 50px;
  justify-content: center;
  align-items: center;
}
.reviews {
  margin-top: -60px;
  width: 100%;
}
.reviewBox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  background-color: white;
  width: 100%;
  height: max-content;
}
.topReview {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  padding: 3rem 3rem 1.5rem 3rem;
  border-bottom: 1px solid #dee2e6;
  width: 100%;
}
.topReview h4 {
  color: var(--primary);
  font-size: 1.5rem;
  font-weight: 400;
}
.topReview p {
  color: #6b6a75;
  font-size: 0.875rem;
  font-weight: 400;
  text-transform: uppercase;
}
.topReview h4,
.topReview p {
  padding-left: 1.5rem;
}
.reviewBox > p {
  line-height: 1.5;
  padding: 1.5rem 3rem 3rem 3rem;
  color: #6b6a75;
  font-weight: 400;
}
@media screen and (max-width: 664px) {
  .reviews {
    margin-top: -60px;
  }
}
