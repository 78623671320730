.hdr {
  min-height: 80px;
  padding: 8px;
  width: 100%;
  border-bottom: 1px solid #161d26;
  &.center {
    justify-content: center;
    &:hover {
      cursor: pointer;
      background-color: $pinkDark;
    }
  }
  .logo-img {
    // height: 60px;
    // width: 60px;
  }
  .icon-close {
    height: 20px;
    cursor: pointer;
    svg {
      height: 30px;
      width: 30px;
      path {
        stroke: #fff;
        fill: #fff;
      }
    }
  }
}
.user-info {
  margin: 20px 0;
  .user-image {
    height: 70px;
    width: 70px;
    object-fit: contain;
    border-radius: 16px;
  }
  .user-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    margin-top: 10px;
  }
  .user-status {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #c2c2c2;
    .check-icon {
      margin-left: 6px;
    }
  }
}
.items {
  padding: 0 14px;
  .list-item {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    padding: 12px 8px;
    border-radius: 16px;
    cursor: pointer;
    .li {
      width: 100%;
      min-width: max-content;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #c2c2c2;
      // padding: 6px 8px;
      @include media("<=desktop") {
        font-size: 15px;
        margin-right: 28px;
        text-align: start;
      }
      @include media("<=tablet") {
        display: flex;
      }
    }
    .ico {
      margin-right: 12px;
    }
    &:hover,
    &.active {
      background: $themeColor;
      @include anim;
      color: #ffffff;
      .li {
        font-weight: 500;
        font-size: 16px;
        color: #ffffff;
      }
    }
  }
  .chat-sec {
    padding: 32px 0;
    .sec-title {
      margin-bottom: 18px;
      padding: 0 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #c2c2c2;
    }
    .list-item {
      margin-bottom: 0;
      justify-content: start;
      .li {
        width: max-content;
        margin-right: 8px;
      }
      .numb {
        height: 20px;
        width: 20px;
        background: #ff3e46;
        border-radius: 50%;
        color: #ffffff;
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        text-align: center;
      }
    }
  }
}
.sidebar-footer {
  padding: 0 14px;
  padding-bottom: 14px;
  width: 100%;
  .light-dark-mode {
    padding: 4px;
    border-radius: 12px;
    .btn-mode {
      display: flex !important;
      background: transparent;
      border: none;
      border-radius: 10px;
      padding: 4px 8px;
      &.active {
        background: $themeColor;
      }
      .btn-icon {
        margin-right: 4px;
      }
      .btn-lbl {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
        color: #ffffff;
      }
    }
  }
}

// Side Bar
.sidebar-s {
  z-index: 99999;
  width: $pageWidth65;
  height: 100%;
  inset: 0;
  overflow: hidden;
  @include anim;
  @include media("<=desktop") {
    display: none;
  }
  &.full-open {
    width: $pageWidth;
  }
  .side-block {
    width: $pageWidth65;
    height: 100%;
    overflow-y: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    @include anim;
    &::-webkit-scrollbar {
      width: 5px;
    }
    @include media("<=phoneH") {
      width: 70%;
    }
    &.full-open {
      left: 0;
      width: $pageWidth;
      .body {
        overflow-y: hidden;
      }
    }
  }
}

// Side Menu for Mobile
.side-menu {
  background: rgba(0, 0, 0, 0.3);

  height: 100%;
  inset: 0;
  overflow: hidden;
  &.hideMenu {
  }
  &.showMenu {
    z-index: 99999;
  }
  .side-block {
    width: 260px;
    background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(10px);
    height: 100%;
    overflow-y: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    transition: all 0.3s cubic-bezier(0.9, 0.32, 0.46, 1.18) 0s;
    &::-webkit-scrollbar {
      width: 5px;
    }
    @include media(">=phone", "<=480px") {
      width: 70%;
    }
    &.show {
      left: 0;
      .body {
        overflow-y: hidden;
      }
    }
    &.hide {
      left: -450px;
    }
  }
}
