.transaction-history-page {
  padding: 40px 0;
  width: 100%;
  height: 100%;
  min-height: 730px;
  .wrap {
    .main-table-sec {
      width: 100%;
      .sc-header {
        gap: 12px;
        margin-bottom: 40px;
        @include media("<=phoneH") {
          flex-direction: column;
          align-items: start;
        }
        .sec-title {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }
        .filter-sec {
          gap: 20px;
          @include media("<=phoneH") {
            flex-direction: column;
            width: 100%;
          }
          .select {
            min-width: 180px;
            background: #12181f;
            @include media("<=phoneH") {
              width: 100%;
            }
            .css-13cymwt-control,
            .css-t3ipsp-control {
              background: #12181f;
              border-radius: 15px;
              &:hover {
                border-color: $themeColor;
              }
            }
            .css-1u9des2-indicatorSeparator {
              display: none;
            }
            .css-1dimb5e-singleValue {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: #c2c2c2;
            }
            .css-qbdosj-Input {
              input {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #c2c2c2;
              }
            }
            .css-1nmdiq5-menu {
              background: #12181f;
            }
          }
          .find-box {
            // width: 330px;
            border-radius: 14px;
            background: #12181f;
            border: 1.6px solid transparent;
            overflow: hidden;
            @include anim;
            &:focus-within {
              border-color: $themeColor;
            }
            @include media("<=phoneH") {
              width: 100%;
            }
            .search-icon {
              padding: 10px;
            }
            .find-icon {
              cursor: pointer;
              padding: 6px;
              border-radius: 10px;
            }
            .txt {
              margin: 0 10px;
              color: #92929d;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
            }
          }
        }
      }
      .transaction-table {
        overflow: hidden;
        overflow-y: auto;
        @include media("<=tablet") {
          overflow: auto;
          &::-webkit-scrollbar {
            height: 6px;
          }
        }
        @include media("<=phoneH") {
        }
        .tran-tbl {
          width: 100%;
          .row {
            gap: 12px;
            padding: 12px 0;
            @include media("<=tablet") {
              width: 1000px;
            }
            @include media("<=phoneH") {
              width: 1000px;
            }
            .row-item {
              flex: 1;
              .lbl {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: #ffffff;
              }
              .icon {
                margin-left: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .coin-img {
                height: 32px;
                width: 32px;
                object-fit: contain;
                border-radius: 50%;
              }
              .amount {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #ffffff;
              }
              .type {
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: #a4a8ab;
              }
              .val {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 21px;
                color: #ffffff;
              }
              .type-box {
                padding: 8px;
                color: #0caf60;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 21px;
                border-radius: 12px;
                &.declined {
                  color: #ff5449;
                }
                &.pending {
                  color: #fb774a;
                }
                .type-icon {
                  margin-right: 8px;
                }
                .type-lbl {
                  margin-top: 2px;
                  margin-left: 6px;
                }
              }
              .status-box {
                padding: 8px 12px;
                color: #0caf60;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 21px;
                background: #1f2f2f;
                border-radius: 12px;
                &.declined {
                  color: #ff5449;
                  background: #332229;
                }
                &.pending {
                  color: #fb774a;
                  background: rgba(247, 147, 26, 0.15);
                }
              }
              &:first-child {
                flex: 0.4;
              }
              &:last-child,
              &:nth-child(7) {
                flex: 0.6;
              }
            }
            &:first-child {
              padding: 10px 0;
              border-bottom: 1px solid rgba(36, 45, 57, 0.6);
              .row-item {
                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
          .table-body {
            max-height: 430px;
            overflow: hidden;
            overflow-y: auto;
            @include media("<=tablet") {
              width: 1000px;
            }
            .row {
              &:first-child {
                border-bottom: none;
                .row-item {
                  &:hover {
                    cursor: default;
                  }
                }
              }
            }
          }
        }
      }
      .table-footer {
        padding: 40px 0;
        margin-top: 70px;
        .load-more {
          flex: 1;
          .btn-more {
            display: flex !important;
            align-items: center;
            justify-content: center;
            border-color: transparent;
            background: transparent;
            padding: 12px;
            border-radius: 12px;
            .ico {
              border-radius: 4px;
              height: 20px;
              width: 20px;
              align-items: flex-end;
              border: 1px solid #e5e5e5;
            }
            .btn-lbl {
              margin-left: 12px;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              text-align: center;
              color: #e5e5e5;
            }
            &:hover {
              border-color: $themeColor;
            }
          }
        }
        .total-record {
          .load-icon {
            @include anim;
            margin-right: 10px;
            svg {
              height: 20px;
              width: 20px;
            }
            &:hover {
              svg {
                path {
                  fill: $themeColor;
                }
              }
            }
          }
          .numb {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #ffffff;
          }
          .lbl {
            margin-left: 4px;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #a4a8ab;
          }
        }
      }
    }
  }
}
