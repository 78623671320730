$themeColor: #0CAF60;
$pinkDark: #ae2744;
$themeColorHover: #fff;
$pageBackground: #ffffff;
$lightButtonColor: #ffffff;
$lightButtonBorderColor: #a0a0a0;
$lightButtonColorHover: #f1f1f1;

$red: #d61810;
$yellow: #f2b239;
$green: #10a310;
$white: #ffffff;
$black:#151515;

.color {
  color: $themeColor;
}
.white {
  color: $white;
}
.red {
  color: $red;
}
.yellow {
  color: $yellow;
}
.green {
  color: $green;
}
