.search-box {
  width: 330px;
  padding: 10px 16px;
  border-radius: 14px;
  border: 1.6px solid transparent;
  @include anim;
  &:focus-within {
    border-color: $themeColor;
  }
  .search-icon {
  }
  .txt {
    margin: 0 10px;
    color: #92929d;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
}
