.login-page {
  min-height: 100vh;
  padding: 60px 0;
  .wrap {
    .main-box {
      width: 80%;
      padding: 16px 12px;
      border-radius: 35px;
      gap: 30px;
      @include media("<=desktop") {
        width: 100%;
      }
      @include media("<=tablet") {
        gap: 15px;
        flex-direction: column;
      }
      .box-left-side {
        flex: 0.5;
        border-radius: 35px;
        padding: 30px 60px;
        @include media("<=tablet") {
          padding: 20px;
          flex: 0.7;
        }
        .title-box {
          .sec-title {
            font-weight: 600;
            font-size: 26px;
            line-height: 45px;
            color: #ffffff;
          }
          .sec-desc {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #c3cddb;
          }
        }
        .forgot-pass {
          margin-top: 20px;
          color: #fff;
          text-align: right;
        }
        .selection-tabs {
          margin: 20px 0;
          .tab-box {
            padding: 6px 8px;
            border-radius: 12px;
            .btn {
              width: 80px;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: #ffffff;
              padding: 5px 4px;
              border-radius: 11px;
              background: transparent;
              border: none;
              @include anim;
              &.active {
                background: $themeColor;
              }
            }
          }
        }
        .form {
          .input-field {
            border-radius: 25px;
            padding: 15px;
            border: 1px solid transparent;
            @include anim;
            margin-bottom: 20px;
            &:focus-within {
              border-color: $themeColor;
            }
            .input-icon {
              svg {
                height: 18px;
                width: 18px;
              }
            }
            .txt {
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: #808195;
              margin-left: 8px;
              padding: 0 8px;
              border-left: 1px solid rgb(195, 205, 219, 0.4);
            }
          }
          .btn-continue {
            display: flex !important;
            border-radius: 23px;
            .btn-lbl {
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: #ffffff;
            }
            .btn-icon {
              padding: 8px;
              border-radius: 12px;
              background: rgba(255, 255, 255, 0.2);
              svg {
                height: 12px;
                width: 12px;
              }
            }
          }
        }
        .socials-login {
          padding-top: 60px;
          .social-tag {
            border-bottom: 1px solid #303a46;
            .social-lbl {
              padding: 0 12px;
              font-weight: 500;
              font-size: 14px;
              line-height: 140%;
              text-align: center;
              color: #ffffff;
            }
          }
          .social-icons {
            margin-top: 25px;
            gap: 10px;
            .icon {
              cursor: pointer;
              height: 38px;
              width: 38px;
              padding: 8px;
              border-radius: 20px;
              @include anim;
              border: 1px solid transparent;
              &:hover {
                border-color: $themeColor;
              }
            }
          }
        }
      }
      .box-right-side {
        flex: 1;
        .qr-code {
          margin-top: 170px;
          @include media("<=phoneH") {
            margin-top: 50px;
          }
          .code-img {
            height: 140px;
            width: 140px;
          }
          .qr-desc {
            font-weight: 600;
            font-size: 18px;
            line-height: 36px;
            color: #ffffff;
            margin-top: 15px;
          }
          .qr-app {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #ffffff;
          }
        }
        .url-lock-sec {
          margin-top: 44px;
          .url-box {
            padding: 4px;
            border-radius: 16px;
            .lock-icon {
              padding: 3px 10px;
              border-radius: 12px;
              background: $themeColor;
            }
            .url {
              margin: 0 10px;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #ffffff;
            }
          }
          .slogan {
            margin-top: 80px;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            text-align: center;
            color: #a4a8ab;
          }
        }
      }
    }
  }
}
