.settings-page {
  padding: 40px 0;
  width: 100%;
  height: 100%;
  min-height: 730px;
  .wrap {
    @include media("<=desktop") {
    }
    .page-header {
      margin-bottom: 40px;
      @include media("<=phoneH") {
        margin-bottom: 20px;
      }
      .page-title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
      .question-icon {
        height: 40px;
        width: 40px;
        padding: 4px;
        border-radius: 12px;
        cursor: pointer;
        @include anim;
        border: 1px solid transparent;
        &:hover {
          border-color: $themeColor;
          svg {
            path {
              fill: $themeColor;
            }
          }
        }
      }
    }
    .section-block {
      .sec-tag {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #0caf60;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
      }
      .row {
        grid-gap: 40px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
      }
      .row2 {
        grid-gap: 40px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        @include media("<=tablet") {
          grid-template-columns: repeat(1, 1fr);
        }
      }
      .input-field {
        width: 100%;
        .field-tag {
          margin-right: 45px;
          min-width: max-content;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: rgba(255, 255, 255, 0.8);
          @include media("<=phoneH") {
            margin-right: 20px;
            width: 130px;
          }
        }
        .input-box {
          width: 100%;
          padding: 10px 14px;
          border-radius: 25px;
          border: 1px solid transparent;
          @include anim;
          &:focus-within {
            border-color: $themeColor;
          }
          .flag-icon {
            .img {
              height: 22px;
              width: 22px;
            }
          }
          .txt {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: rgba(255, 255, 255, 0.8);
          }
        }
      }
      .show-password-action {
        .show-pass-lbl {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: rgba(255, 255, 255, 0.8);
          margin-right: 12px;
        }
        .icon {
          cursor: pointer;
          @include anim;
          svg {
            height: 24px;
            width: 24px;
          }
          &:hover {
            svg {
              path {
                stroke: $themeColor;
              }
            }
          }
        }
      }
      .qr-row {
        gap: 30px;
        .tabs-sec {
          width: 100%;
          height: max-content;
          @include media("<=phoneH") {
            width: 100%;
          }
          .field-tag {
            margin-right: 45px;
            min-width: max-content;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: rgba(255, 255, 255, 0.8);
          }
          .tabs-box {
            border-radius: 16px;
            padding: 4px;
            border: 1px solid #212a36;
            backdrop-filter: blur(21px);
            @include media("<=phoneH") {
              width: 100%;
            }
            .btn-tab {
              border-radius: 14px;
              width: 80px;
              border: none;
              padding: 10px;
              background: transparent;
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #a4a8ab;
              &.active {
                color: #ffffff;
                background: $themeColor;
              }
              @include media("<=phoneH") {
                width: 100%;
              }
            }
          }
        }
        .auths {
          .field-tag {
            min-width: max-content;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: rgba(255, 255, 255, 0.8);
            margin-bottom: 10px;
          }
          .auth-item {
            margin-bottom: 12px;
            &:last-child {
              margin-bottom: 0;
            }
            .lbl {
              width: 100px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: rgba(255, 255, 255, 0.8);
            }
            .toggle-btn {
              margin-left: 20px;
              .btn {
              }
              .circle {
                background: transparent;
                border: 1px solid #fff;
              }
            }
          }
        }
        .qr-code-box {
          .qr-img {
            height: 150px;
            width: 140px;
            object-fit: contain;
          }
        }
      }
    }

    .action-section {
      margin: 30px;
      @include media("<=phoneH") {
        flex-direction: column;
        gap: 20px;
        margin: 20px;
      }
      .pin-side {
        .tag {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #ffffff;
        }
        .access-code {
          margin-left: 50px;
          .styles_react-code-input__CRulA {
            display: flex !important;
          }
          .code-val,
          input {
            width: 40px !important;
            height: 30px !important;
            border-radius: 20px;
            padding: 6px 10px;
            background: transparent;
            border: 1px solid $themeColor;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: 0.03em;
            color: #fefefe;
            margin-right: 12px;
            &:focus-within {
              border-color: #fefefe;
            }
          }
        }
      }
      .btn-continue {
        display: flex !important;
        border-radius: 16px;
        @include media("<=phoneH") {
          width: 100%;
        }
        .btn-lbl {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #ffffff;
        }
        .btn-icon {
          margin-left: 12px;
          padding: 8px;
          border-radius: 12px;
          background: rgba(255, 255, 255, 0.2);
          svg {
            height: 12px;
            width: 12px;
          }
        }
      }
    }
  }
}
