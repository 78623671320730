.account {
  flex-direction: column;
  gap: 40px;
  display: flex;
  padding-top: 140px;
  padding-bottom: 50px;
  justify-content: center;
  width: 90%;
  align-items: center;
}
.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
}
.box {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 5px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  padding-bottom: 24px;
}
.recomended {
  background-color: #011150;
  z-index: 1;
  position: relative;
}
.box h4 {
  padding: 1.5rem 3rem;
  border-bottom: 1px solid #dee2e6;
  font-weight: 400;
  width: 100%;
  color: var(--primary);
  font-size: 1.5rem;
}
.recomended h4 {
  color: white;
}
.box h3 {
  line-height: 120%;
  font-size: 3rem;
  color: #011150;
  font-weight: 400;
  padding-inline: 3rem;
  margin: 10px 0px;
}
.recomended h3 {
  color: white;
}
.option {
  padding-inline: 3rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}
.option p {
  color: #6b6a75;
  font-size: 1rem;
  font-weight: 400;
}
.recomended .option p {
  color: white;
}
.option img {
  width: 16px;
  object-fit: contain;
}
.recomended .option img {
  filter: brightness(0) invert(1);
}

.ctaBtn {
  font-family: "Nunito";
  transition: all 0.5s ease-in-out;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  font-size: 1rem;
  border-radius: 2px;
  padding: 1rem 3rem;
}
.ctaBtn {
  background-color: var(--primary);
  color: white;
  border: 1px solid var(--primary);
}
.ctaBtn:hover {
  box-shadow: inset 0 0 0 0 var(--primary);
  color: rgb(0, 0, 0);
  background-color: rgb(82, 185, 110);
  border-color: rgb(82, 185, 110);
}

@media screen and (max-width: 1399px) {
  .box h3 {
    font-size: 2.2rem;
    padding-inline: 2.4rem;
  }
  .box h4 {
    font-size: 1.7rem;
  }
  .option {
    padding-inline: 2.4rem;
  }
}
@media screen and (max-width: 1250px) {
  .grid {
    row-gap: 30px;
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (max-width: 1000px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid .box:last-child {
    grid-column: auto;
  }
}
@media screen and (max-width: 640px) {
  .account {
    padding-top: 60px;
  }
  .grid {
    grid-template-columns: 1fr;
  }
  .grid .box:last-child {
    grid-column: auto;
  }
  .box h3 {
    font-size: calc(1.425rem + 2.1vw);
  }
}
