.footer {
  background: #011150;
  position: relative;
  z-index: 5;
}

.footerGrid {
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-template-rows: auto auto;
  width: 90%;
  max-width: 1320px;
  column-gap: 80px;
}
.topWrapper {
  padding: 80px 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  grid-column: 2/3;
  grid-row: 1/2;
}
.topFooter {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
}
.footCol {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
}
.footHead {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 10px;
}

.footHead h4 {
  color: #eef9ff;
  font-weight: 400;
  font-size: 2.25rem;
  line-height: 1;
}
.footCol a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transition: all 0.4s ease-in-out;
  color: white;
  font-size: 16px;
  font-weight: 400;
}
.footCol svg {
  color: var(--primary);
}
.footCol a:hover {
  transform: translateX(14px);
}
.footHead .line {
  background-color: var(--primary);
  width: 90px;
  height: 3px;
  border-radius: 100vh;
  position: relative;
  overflow: hidden;
}
.footHead .line:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 3px;
  background-color: white;
  left: 0;
  top: 0;
  animation: moving 5s infinite linear alternate;
}
@keyframes moving {
  from {
    left: 0;
  }
  to {
    left: 75%;
  }
}
.bottomWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  min-height: 250px;
  padding: 30px 0px;
  padding-inline: 30px;
  grid-column: 2/3;
  grid-row: 2/3;
}
.bottomFooter {
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
}
.bottomFooter p {
  color: white;
  font-size: 14px;
  font-weight: 400;
}
.greenBox {
  background-color: var(--primary);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  padding: 20px;
  grid-column: 1/2;
  grid-row: 1/3;
}
.greenBox img {
  max-width: 130px;
  object-fit: contain;
}
.greenBox p {
  font-weight: 400;
  color: white;
  line-height: 1.5;
  font-size: 17px;
}
.topBg {
  background-color: #011150;
  position: absolute;
  top: 0;
  width: 100%;
  height: 425px;
  z-index: -2;
}
.bottomBg {
  background-color: #061429;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 250px;
  z-index: -1;
}
@media screen and (max-width: 1200px) {
  .footerGrid {
    grid-template-columns: 300px 1fr;
    column-gap: 40px;
  }
}
@media screen and (max-width: 1040px) {
  .topFooter {
    flex-direction: column;
  }
  .topBg {
    height: 860px;
  }
}
@media screen and (max-width: 700px) {
  .footerGrid {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
  }
  .greenBox {
    grid-column: 1/2;
    grid-row: 1/2;
    margin-bottom: 20px;
    padding: 30px 20px;
  }
  .topWrapper {
    grid-row: 2/3;
    grid-column: 1/2;
    padding: 20px 0px;
  }
  .bottomWrapper {
    grid-row: 3/4;
    grid-column: 1/2;
    padding: 0px;
  }
  .topBg {
    height: 1200px;
  }
}
