.affiliate-page {
  padding: 40px 0;
  width: 100%;
  height: 100%;
  min-height: 730px;
  .wrap {
    gap: 40px;
    @include media("<=desktop") {
      gap: 20px;
    }
    @include media("<=tablet") {
      flex-direction: column;
    }
    .page-header {
      margin-bottom: 40px;
      @include media("<=phoneH") {
        margin-bottom: 20px;
      }
      .page-title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
      .question-icon {
        height: 40px;
        width: 40px;
        padding: 4px;
        border-radius: 12px;
        cursor: pointer;
        @include anim;
        border: 1px solid transparent;
        &:hover {
          border-color: $themeColor;
          svg {
            path {
              fill: $themeColor;
            }
          }
        }
      }
    }

    .page-left {
      flex: 1;
      padding: 20px;
      @include media("<=desktop") {
        padding: 10px;
      }
      .left-top-sec {
        gap: 25px;
        @include media("<=phoneH") {
          flex-direction: column;
        }
        .top-sec-left {
          flex: 0.6;
          .data-field {
            margin-bottom: 36px;
            &:last-child {
              margin-bottom: 0;
            }
            .sec-tag {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 22px;
            }
            .f-tag {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 31px;
            }
            .val {
              padding: 8px;
              border: 1px solid #50565c;
              border-radius: 20px;
            }
          }
        }
        .top-sec-right {
          flex: 1;
          .address-sec {
            margin-bottom: 40px;
            .selection-lbl {
              color: #a4a8ab;
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              margin-bottom: 12px;
            }
            .address-box {
              padding: 6px 8px;
              border-radius: 20px;
              .a-code {
                width: 100%;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #ffffff;
                @include media("<=phoneH") {
                  font-size: 10px;
                  line-height: 19px;
                }
              }
              .btn-copy {
                padding: 5px 14px;
                border-radius: 14px;
                display: flex !important;
                align-items: center;
                .btn-lbl {
                  margin-right: 10px;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 24px;
                }
                .btn-icon {
                  svg {
                    height: 18px;
                    width: 18px;
                  }
                }
              }
            }

            &:last-child {
              margin-bottom: 0;
              .btn-copy {
                border-color: #50565c;
                background: #50565c;
              }
            }
          }
          .action {
            .btn-create {
              border-radius: 20px;
            }
          }
          .affiliate-mechanism-sec {
            margin-top: 30px;
            .sec-tag {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 22px;
              margin-bottom: 18px;
            }
            .affiliate-list {
              .item {
                .dot {
                  height: 8px;
                  width: 8px;
                  border-radius: 50%;
                  background: $themeColor;
                }
                .desc {
                  margin-left: 10px;
                  font-weight: 300;
                  font-size: 14px;
                  line-height: 22px;
                  color: rgba(177, 254, 52, 0.5);
                }
              }
            }
          }
        }
      }
      .tips-section {
        margin-top: 20px;
        .sec-tag {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 12px;
        }
        .tip-box {
          padding: 30px 20px;
          border-radius: 20px;
          @include media("<=phoneH") {
            padding: 20px 10px;
          }
          .tip-item {
            margin-bottom: 24px;
            @include media("<=phoneH") {
              margin-bottom: 15px;
            }
            &:last-child {
              margin-bottom: 0;
            }
            .dot {
              height: 8px;
              width: 8px;
              border-radius: 50%;
              background: $themeColor;
              margin-right: 12px;
              margin-top: 4px;
              @include media("<=phoneH") {
                margin-right: 6px;
                height: 4px;
                width: 4px;
              }
            }
            .desc {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: #a4a8ab;
            }
          }
        }
      }
    }
    .page-right {
      flex: 0.4;
      height: max-content;
      padding: 20px;
      border-radius: 25px;
      border: 1px solid #648d2d;
      .hand-icon {
      }
      .sec-tag {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 27px;
        text-align: center;
        color: #ffffff;
        margin-top: 8px;
      }
      .title {
        margin: 100px 0;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 42px;
        text-align: center;
        background: linear-gradient(180deg, #9df42d 0%, #147b37 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
      .sec-description {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        color: #ffffff;
      }
    }
  }
}
