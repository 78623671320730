@import "./Colors.scss";
@import "./Props.scss";
@import "./media.scss";
@import "./global.scss";

@import "../components/Header/style.scss";
@import "../components/Footer/style.scss";
@import "../components/Sidebar/style.scss";
@import "../components/SearchBox/search-box-style.scss";
@import "../Pages/Login/login-style.scss";
@import "../Pages/Dashboard/style.scss";
@import "../Pages/Deposit/deposit-style.scss";
@import "../Pages/Deposit1/deposit1-style.scss";
@import "../Pages/Deposit2/deposit2-style.scss";
@import "../Pages/Withdraw/withdraw-style.scss";
// @import "../Pages/myinvestments/transaction-style.scss";
@import "../Pages/MyInvestments/transaction-style.scss";
@import "../Pages/Affiliate/affiliate-style.scss";
@import "../Pages/Settings/settings-style.scss";

.font,
body {
  font-family: "Poppins", sans-serif;
}

@mixin bgImage($radius: 50%) {
  height: 100%;
  width: 100%;
  border-radius: $radius;
  background-color: #e2e2e2;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

@mixin dropDownAnim($duration: 0.3s) {
  animation-name: drop;
  animation-duration: $duration;
  transform-origin: top;
}

@keyframes drop {
  0% {
    transform: scaleY(0.5);
    opacity: 0;
  }
}

.wrapWidth {
  width: 100%;
  padding: 0px 5%;
  // width: 1300px;
  margin: 0 auto;
  @include media("<=xlarge") {
    width: 100%;
    padding: 0px 5%;
    // width: 1160px;
    margin: 0 auto;
  }
  @include media("<=large") {
    width: 100%;
    padding: 0px 5%;
  }
  @include media("<=desktop") {
    width: 100%;
    padding: 0px 3%;
  }
  @include media("<=tablet") {
    width: 100%;
    padding: 0px 3%;
  }
  @include media(">=phone", "<=480px") {
    padding: 0px 5%;
  }
}
.wrapWidthHeader {
  padding: 0px 3%;
  width: 1380px;
  margin: 0 auto;
  @include media("<=xlarge") {
    padding: 0px 3%;
    width: 1280px;
    margin: 0 auto;
  }
  @include media("<=large") {
    width: 100%;
    padding: 0px 6%;
  }
  @include media("<=desktop") {
    width: 100%;
    padding: 0px 5%;
  }
  @include media("<=tablet") {
    width: 100%;
    padding: 0px 3%;
  }
  @include media("<=phoneH") {
    width: 100%;
    padding: 0px 3%;
  }
}

.App {
  min-height: 100vh;
  color: #ffffff;
  background: #161d26;
}

#root {
  background: #161d26;
}
//Drop Down
.dropDown {
  width: 100%;
  .category {
    width: 100%;
    .cbox {
      border: 1.5px solid #d7d7d7;
      padding: 0 16px;
      min-height: 42px;
      border-radius: 5px;
      box-sizing: border-box;
      min-width: 180px;
      width: 100%;
      justify-content: space-between;
      @include media(">=phone", "<=480px") {
        min-width: 150px;
      }
      .icon-arrow {
        svg {
          path {
            fill: $themeColor;
          }
        }
      }
      .slt {
        overflow: hidden;
        .ico {
          cursor: pointer;
          color: #000000d9;
          height: 22px;
          display: flex;
          align-items: center;
        }
        .lbl {
          color: #000;
          padding: 0 10px;
        }
        .unit-name {
          .unit-eng {
            color: #000000;
          }
        }
      }
      .d-img {
        margin: 2px 10px;
        .img {
          height: 25px;
          width: 25px;
          padding: 2.5px;
          border-radius: 100%;
          border: 1px solid $themeColor;
        }
      }
      .edit-icon {
        margin: 2px 10px;
        padding: 4px;
        border-radius: 100%;
        border: 1px solid $themeColor;
      }
      .dropDown-icon {
        cursor: pointer;
      }
    }
  }
  .block {
    top: 45px;
    left: 0;
    right: 0;
    border-radius: 4px;
    border: 1px solid $themeColor;
    background: #ffffff;
    box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
    visibility: hidden;
    opacity: 0;
    z-index: 2;
    width: 100%;
    .manue {
      width: 100%;
      // height: 150px;
      overflow-y: auto;
      padding: 0px 0px;
      &::-webkit-scrollbar {
        width: 4px;
      }
      .slt {
        width: 100%;
        padding: 8px 16px;
        @include anim;
        &.active {
          background-color: $themeColor;
          color: #000000;
          .unit-name {
            .unit-eng {
              color: #000000;
            }
          }
        }
        .unit-name {
          .unit-eng {
            color: #000000;
          }
        }
        .ico {
          justify-content: flex-start;
          margin-right: 8px;
          width: 25px;
          height: 25px;
        }
        .lbl {
          flex: 1;
          margin-left: 5px;
          justify-content: flex-start;
        }
        &:hover {
          background-color: $themeColor;
          cursor: pointer;
          .ico {
            color: $themeColor;
          }
          .lbl {
            color: #ffffff;
          }
          .unit-name {
            .unit-eng {
              color: #ffffff;
            }
          }
        }
      }
    }
    &.show {
      display: flex;
      visibility: visible;
      opacity: 1;
      @include dropDownAnim;
    }
  }
}

// Loader
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-cmp {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  background: rgba(255, 255, 255, 0.65);
  z-index: 9999999999;
  overflow: hidden;
  position: absolute;
  inset: 0;
  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    &:after {
      content: " ";
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid $themeColor;
      border-color: $themeColor transparent $themeColor transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
  }
}

// Dashboard
.dashboard-page {
  height: 100%;
  min-height: 730px;
  margin-left: $pageWidth65;
  @include anim;
  &.full-open {
    margin-left: $pageWidth;
  }
  @include media("<=desktop") {
    &.full-open {
      margin-left: 0;
    }
  }
}

// Toggle
.toggle-btn {
  flex: 0.4;
  .btn {
    display: flex !important;
    border-radius: 50px;
    padding: 10px 22px !important;
    border: 2px solid #ffffff;
    background: rgba(203, 57, 37, 0.8);
    opacity: 0.4;
    .circle {
      left: 2px;
      // margin-bottom: 1px;
      padding: 6px;
      background: #fff;
      border-radius: 50%;
      border: 2px solid #ffffff;
    }
    &.on {
      background: #26c265;
      opacity: 1;
      .circle {
        left: 25px;
        // background: $themeColor !important;
      }
    }
  }
}
