.ctaBtn,
.ctaInverBtn {
  font-family: "Nunito";
  transition: all 0.5s ease-in-out;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  font-size: 1rem;
  border-radius: 2px;
}
.ctaBtn {
  background-color: var(--primary);
  color: white;
  border: 1px solid var(--primary);
}
.ctaBtn:hover {
  box-shadow: inset 0 0 0 0 var(--primary);
  color: rgb(0, 0, 0);
  background-color: rgb(82, 185, 110);
  border-color: rgb(82, 185, 110);
}
.ctaInverBtn {
  color: rgb(82, 185, 110);
  background-color: rgba(52, 173, 84, 0);
  border-color: rgb(90, 174, 45);
  border: 1px solid;
}
.ctaInverBtn:hover {
  box-shadow: inset 0 0 0 0 var(--secondary);
  color: rgb(0, 0, 0);
  background-color: rgb(82, 185, 110);
  border-color: rgb(72, 181, 101);
}
