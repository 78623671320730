.about {
  justify-content: space-between;
  align-items: flex-start;
  gap: 30px;
  padding: 100px 0px;
}
.about > img {
  width: 100%;
  max-width: 624px;
  object-fit: contain;
}
.rightAbout {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-width: 672px;
}
.rightAbout h4 {
  color: var(--primary);
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
}
.rightAbout h3 {
  color: white;
  font-size: 36px;
  font-weight: 400;
}
.rightAbout > p {
  color: white;
  font-size: 17px;
}
.grid {
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 30px;
  display: grid;
  margin-top: 15px;
  margin-bottom: 20px;
}
.option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.option img {
  width: 16px;
  object-fit: contain;
}
.option p {
  color: white;
  font-size: 17px;
}
@media screen and (max-width: 1350px) {
  .about > img {
    max-width: 500px;
  }
}
@media screen and (max-width: 1120px) {
  .about {
    flex-direction: column-reverse;
    gap: 30px;
    align-items: center;
    padding: 50px 0px;
  }
  .rightAbout {
    max-width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .rightAbout > h3 {
    font-size: 26px;
  }
  .rightAbout > p {
    line-height: 1.5;
  }
  .rightAbout p,
  .rightAbout h4 {
    font-size: 14px;
  }
}
