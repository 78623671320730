.withdraw-page {
  padding: 40px 0;
  width: 100%;
  height: 100%;
  min-height: 730px;
  .wrap {
    gap: 40px;
    @include media("<=desktop") {
      gap: 20px;
    }
    @include media("<=tablet") {
      flex-direction: column;
    }
    .page-header {
      margin-bottom: 40px;
      @include media("<=phoneH") {
        margin-bottom: 20px;
      }
      .page-title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
      .question-icon {
        height: 40px;
        width: 40px;
        padding: 4px;
        border-radius: 12px;
        cursor: pointer;
        @include anim;
        border: 1px solid transparent;
        &:hover {
          border-color: $themeColor;
          svg {
            path {
              fill: $themeColor;
            }
          }
        }
      }
    }
    .page-left {
      flex: 1;
      padding: 20px;
      @include media("<=desktop") {
        padding: 10px;
      }
      .coin-selection {
        margin-bottom: 20px;
        @include media("<=phoneH") {
          margin-bottom: 14px;
        }
        .selection-lbl {
          color: #a4a8ab;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 12px;
        }
        .dropDown {
          .category {
            padding: 6px 8px;
            border-radius: 20px;
            .cbox {
              border: none;
              padding: 0 6px;
              min-width: 110px;
              .slt {
                .selected-token {
                  padding: 6px;
                  border-radius: 10px;
                  margin-right: 12px;
                  .icon {
                    margin-right: 5px;
                    .img {
                      height: 24px;
                      width: 24px;
                      border-radius: 50%;
                    }
                  }
                  .token-name {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #ffffff;
                    margin-left: 8px;
                  }
                }
                .unit-name {
                  .unit-eng {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #a4a8ab;
                  }
                }
              }
              .arrow-icon {
                height: 34px;
                width: 34px;
                padding: 8px;
                background: #161d26;
                border-radius: 12px;
              }
            }
          }
        }
      }
      .total-balance {
        .b-lbl {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #ffffff;
          margin-right: 18px;
        }
        .b-val {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
        }
        .load-icon {
          @include anim;
          margin-left: 8px;
          svg {
            height: 18px;
            width: 18px;
          }
          &:hover {
            cursor: pointer;
            svg {
              path {
                fill: $themeColor;
              }
            }
          }
        }
      }
      .input-field {
        padding: 10px 12px;
        border-radius: 20px;
        .icon {
          padding-right: 8px;
          border-right: 1px solid #c3cddb;
        }
        .txt {
          font-weight: 400;
          font-size: 14px;
          margin: 0 8px;
        }
        .btn-action {
          padding: 6px 8px;
          border: none;
          min-width: max-content;
          background: transparent;
          text-decoration: underline;
        }
      }
      .action-section {
        margin: 20px 0;
        @include media("<=phoneH") {
          flex-direction: column;
          gap: 20px;
        }
        .pin-side {
          .tag {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #ffffff;
          }
          .access-code {
            margin-left: 12px;
            .styles_react-code-input__CRulA {
              display: flex !important;
            }
            .code-val,
            input {
              width: 40px !important;
              height: 30px !important;
              border-radius: 20px;
              padding: 6px 10px;
              background: transparent;
              border: 1px solid $themeColor;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              font-family: "Open Sans";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 140%;
              letter-spacing: 0.03em;
              color: #fefefe;
              margin-right: 12px;
              &:focus-within {
                border-color: #fefefe;
              }
            }
          }
        }
        .btn-continue {
          display: flex !important;
          border-radius: 16px;
          @include media("<=phoneH") {
            width: 100%;
          }
          .btn-lbl {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #ffffff;
          }
          .btn-icon {
            margin-left: 12px;
            padding: 8px;
            border-radius: 12px;
            background: rgba(255, 255, 255, 0.2);
            svg {
              height: 12px;
              width: 12px;
            }
          }
        }
      }
      .tips-section {
        margin-top: 30px;
        .sec-tag {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 12px;
        }
        .tip-box {
          padding: 30px 20px;
          border-radius: 20px;
          @include media("<=phoneH") {
            padding: 20px 10px;
          }
          .tip-item {
            margin-bottom: 24px;
            @include media("<=phoneH") {
              margin-bottom: 15px;
            }
            &:last-child {
              margin-bottom: 0;
            }
            .dot {
              height: 8px;
              width: 8px;
              border-radius: 50%;
              background: $themeColor;
              margin-right: 12px;
              margin-top: 4px;
              @include media("<=phoneH") {
                margin-right: 6px;
                height: 4px;
                width: 4px;
              }
            }
            .desc {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: #a4a8ab;
            }
          }
        }
      }
    }
    .page-right {
      flex: 0.4;
      padding: 20px;
      border-radius: 25px;
      border: 1px solid #648d2d;
      .hand-icon {
      }
      .sec-tag {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 27px;
        text-align: center;
        color: #ffffff;
        margin-top: 8px;
      }
      .title {
        margin: 100px 0;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 42px;
        text-align: center;
        background: linear-gradient(180deg, #9df42d 0%, #147b37 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
      .sec-description {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        color: #ffffff;
      }
    }
  }
}
