.deposit1-page {
  padding: 40px 0;
  width: 100%;
  height: 100%;
  min-height: 730px;
  .wrap {
    gap: 40px;
    @include media("<=desktop") {
      gap: 20px;
    }
    @include media("<=tablet") {
      flex-direction: column;
    }
    .page-header {
      margin-bottom: 40px;
      @include media("<=phoneH") {
        margin-bottom: 20px;
      }
      .page-title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
      .question-icon {
        height: 40px;
        width: 40px;
        padding: 4px;
        border-radius: 12px;
        cursor: pointer;
        @include anim;
        border: 1px solid transparent;
        &:hover {
          border-color: $themeColor;
          svg {
            path {
              fill: $themeColor;
            }
          }
        }
      }
    }
    .tips-section {
      margin-top: 30px;
      .sec-tag {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 12px;
      }
      .tip-box {
        padding: 30px 20px;
        border-radius: 20px;
        @include media("<=phoneH") {
          padding: 20px 10px;
        }
        .tip-item {
          margin-bottom: 24px;
          @include media("<=phoneH") {
            margin-bottom: 15px;
          }
          &:last-child {
            margin-bottom: 0;
          }
          .dot {
            height: 8px;
            width: 8px;
            border-radius: 50%;
            background: $themeColor;
            margin-right: 12px;
            margin-top: 4px;
            @include media("<=phoneH") {
              margin-right: 6px;
              height: 4px;
              width: 4px;
            }
          }
          .desc {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #a4a8ab;
          }
        }
      }
    }
    .page-left {
      flex: 1;
      padding: 20px;
      @include media("<=desktop") {
        padding: 10px;
      }
      .btm-values {
        .lbl {
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 21px;
          color: rgba(255, 255, 255, 0.5);
        }
        .val {
          margin-left: 8px;
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: rgba(255, 255, 255, 0.95);
        }
      }
      .coin-selection {
        margin: 30px 0;
        .sec-title {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #a4a8ab;
          margin-bottom: 30px;
        }
        .selection-lbl {
          color: #a4a8ab;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 12px;
        }
        .dropDown {
          .category {
            padding: 6px 8px;
            border-radius: 20px;
            .cbox {
              border: none;
              padding: 0 6px;
              min-width: 110px;
              .slt {
                .selected-token {
                  padding: 6px;
                  border-radius: 10px;
                  margin-right: 12px;
                  .icon {
                    margin-right: 5px;
                    .img {
                      height: 24px;
                      width: 24px;
                      border-radius: 50%;
                    }
                  }
                  .token-name {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #ffffff;
                    margin-left: 8px;
                  }
                }
                .unit-name {
                  .unit-eng {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #a4a8ab;
                  }
                }
              }
              .arrow-icon {
                height: 34px;
                width: 34px;
                padding: 8px;
                background: #161d26;
                border-radius: 12px;
              }
            }
          }
        }
        .btm-values {
          margin-top: 8px;
        }
      }
      .field-sec {
        .field-hdr {
          margin-bottom: 12px;
          .hdr-lbl {
            color: #a4a8ab;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
          }
        }
        .input-field {
          padding: 10px 12px;
          border-radius: 20px;
          .icon {
            padding-right: 8px;
            border-right: 1px solid #c3cddb;
          }
          .txt {
            font-weight: 400;
            font-size: 14px;
            margin: 0 8px;
          }
          .inc-actions {
            gap: 20px;
            .inc-btn {
              width: 60px;
              border: none;
              padding: 4px 8px;
              border-radius: 20px;
            }
          }
        }
      }
      .action-section {
        margin: 20px 0;
        @include media("<=phoneH") {
          flex-direction: column;
          gap: 20px;
        }
        .pin-side {
          .tag {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #ffffff;
          }
        }
        .btn-continue {
          display: flex !important;
          border-radius: 16px;
          @include media("<=phoneH") {
            width: 100%;
          }
          .btn-lbl {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #ffffff;
          }
          .btn-icon {
            margin-left: 12px;
            padding: 8px;
            border-radius: 12px;
            background: rgba(255, 255, 255, 0.2);
            svg {
              height: 12px;
              width: 12px;
            }
          }
        }
      }

      .tips-section {
        @include media("<=tablet") {
          display: none;
        }
      }
    }
    .page-right {
      flex: 0.5;
      padding: 20px;
      @include media("<=desktop") {
        padding: 10px;
      }
      .right-top {
        .sec-title {
          .tag {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #ffffff;
            margin-right: 8px;
          }
          .icon {
            svg {
              height: 24px;
              width: 24px;
            }
          }
        }
        .exchange-sec {
          gap: 12px;
          align-items: center;
          justify-content: center;
          margin: 40px 0;
          .exchange-box {
            width: 100%;
            height: 95px;
            padding: 14px;
            background: #12181f;
            border-radius: 20px;
            overflow: hidden;
            .up {
              margin-bottom: 10px;
              .up-lbl {
                font-weight: 400;
                font-size: 11px;
                line-height: 20px;
                color: #a4a8ab;
              }
            }
            .btm {
              .icon {
                .img {
                  height: 24px;
                  width: 24px;
                  object-fit: contain;
                }
              }
              .btm-val {
                font-weight: 500;
                font-size: 14px;
                line-height: 27px;
                color: #ffffff;
              }
              .input-txt {
                width: 60px;
                min-width: max-content;
                font-weight: 500;
                font-size: 14px;
                line-height: 27px;
                color: #ffffff;
                padding: 4px 6px;
                border-radius: 12px;
                border: 1px solid transparent;
                &:focus {
                  border-color: $themeColor;
                }
              }
            }
          }
          .exh-btn {
            height: 36px;
            width: 36px;
            border-radius: 50%;
            border: 4px solid #161d26;
            background: $themeColor;
            cursor: pointer;
          }
        }
      }
      .description-sec {
        .warn-icon {
        }
        .sec-tag {
          font-weight: 500;
          font-size: 16px;
          line-height: 27px;
          color: #ffffff;
          text-align: center;
          margin: 10px 0;
        }
        .desc {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #a4a8ab;
        }
      }
      .tips-section {
        display: none;
        @include media("<=tablet") {
          display: flex;
        }
      }
    }
  }
}
