@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-family: "Rubik", sans-serif;
}
:root {
  --primary: #5aae2d;
  --secondary: rgb(52, 173, 84);
  --light: #eef9ff;
  --dark: #011150;
}
.nunito {
  font-family: "Nunito", sans-serif;
}
.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
a {
  text-decoration: none;
}
button {
  background-color: transparent;
  cursor: pointer;
}
.contain {
  display: flex;
  width: 90%;
  max-width: 1320px;
}
.hero__slider .splide__slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero__slider .splide__arrow {
  opacity: 1;
  background-color: transparent;
  background: transparent;
  /* width: 30px; */
}
.hero__slider .splide__arrow svg {
  fill: var(--primary);
  font-size: 30px;
}
.hero__slider .splide__arrow--prev {
  left: 2%;
}
.hero__slider .splide__arrow--next {
  right: 2%;
}
.review-slider .splide__track {
  padding: 40px 0px !important;
}
.review-slider .splide__slide.is-active > div {
  box-shadow: 0 0 30px #dddddd;
  position: relative;
  z-index: 2;
}
.review-slider .splide__pagination {
  bottom: initial;
  top: 100%;
  gap: 8px;
}
.review-slider .splide__pagination__page {
  width: 15px;
  height: 15px;
  background: #dddddd;
  border-radius: 2px;
  transition: 0.5s;
  opacity: 1;
  transform: none;
}
.review-slider .splide__pagination__page.is-active {
  transform: none;
  width: 30px;
  background-color: var(--primary);
}
@media screen and (max-width: 450px) {
  .hero__slider .splide__arrow--next,
  .hero__slider .splide__arrow--prev {
    top: 36%;
  }
}
@media screen and (max-width: 420px) {
  .hero__slider .splide__arrow--next,
  .hero__slider .splide__arrow--prev {
    top: 42%;
  }
}
@media screen and (max-width: 380px) {
  .hero__slider .splide__arrow--next,
  .hero__slider .splide__arrow--prev {
    top: 44%;
  }
}
