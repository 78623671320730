// Header
.header-camp {
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 80px;
  padding: 8px 0px;
  position: sticky;
  box-shadow: 0px 4px 250px rgba(47, 79, 162, 0.14);
  .wrap {
    gap: 30px;
    .left-side {
      flex: 1;
      .mobile-menu-sec {
        display: none;
        @include media("<=desktop") {
          display: flex;
        }
        .menu-logo {
          height: 70px;
          width: 70px;
          @include media("<=tablet") {
            height: 60px;
            width: 60px;
            .logo-img {
              height: 100%;
              width: 100%;
            }
          }
        }
        .menu-icon {
          cursor: pointer;
          margin-right: 30px;
          @include anim;
          svg {
            path {
              fill: #ffffff;
            }
          }
          &:hover {
            svg {
              path {
                fill: $themeColor;
              }
            }
          }
        }
      }
      .dashboard-icon {
        margin-right: 30px;
        @include media("<=desktop") {
          display: none;
        }
        svg {
          height: 20px;
          width: 20px;
        }
      }
      .search-box {
        @include media("<=desktop") {
          display: none;
        }
      }
    }
    .right-side {
      gap: 8px;
      .ico {
        padding: 14px;
        border-radius: 20px;
        cursor: pointer;
        border: 1px solid transparent;
        @include anim;
        &:hover {
          border-color: $themeColor;
        }
        svg {
          height: 20px;
          width: 20px;
        }
      }
      .bell-icon {
        .numb {
          top: 6px;
          right: 6px;
          height: 20px;
          width: 20px;
          background: #ff3e46;
          border-radius: 50%;
          color: #ffffff;
          font-weight: 400;
          font-size: 11px;
          line-height: 16px;
          text-align: center;
        }
      }
      .language-dropdown {
        @include media("<=phoneH") {
          display: none;
        }
      }
    }
    //hidden and Show
    .hide {
      visibility: hidden;
      opacity: 0;
    }
    .show {
      visibility: visible;
      opacity: 1;
    }
  }
}

.login-page-header {
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 80px;
  padding: 12px 0px;
  position: sticky;
  box-shadow: 0px 4px 250px rgba(47, 79, 162, 0.14);
  border-radius: 0 0 12px 12px;
  .wrap {
    gap: 30px;
    .left-side {
      flex: 1;
      .logo {
        .logo-img {
        }
      }
      .dashboard-icon {
        margin-top: 10px;
        margin-left: 60px;
        margin-right: 30px;
        @include media("<=phoneH") {
          margin-left: 20px;
        }
        svg {
          height: 20px;
          width: 20px;
        }
      }
      .menu-list {
        margin-top: 10px;
        @include media("<=tablet") {
          display: none;
        }
        .menu-item {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
          margin-right: 30px;
          cursor: pointer;
          @include anim;
          &:hover {
            color: $themeColor;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .right-side {
      .language-dropdown {
        @include media("<=tablet") {
          padding: 6px 6px;
        }
      }
    }
  }
}

.language-dropdown {
  padding: 10px 12px;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid transparent;
  @include anim;
  &:hover {
    border-color: $themeColor;
  }

  .flag-icon {
    .img {
      height: 28px;
      width: 28px;
    }
  }
  .language-name {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    margin: 0 14px;
  }
  .icon {
  }
}

// Notification Dropdown
.notification-menu {
  background: #fff;
  width: 180px;
  position: absolute;
  top: 45px;
  right: 0px;
  padding: 10px 0px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 16px;
  border-radius: 4px;
  max-height: 260px;
  overflow: hidden;
  overflow-y: auto;
  &.show {
    @include dropDownAnim;
  }
  .tag {
    color: #121212;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 20px;
    cursor: pointer;
    &:hover {
      color: #ffffff;
      background: $themeColor;
    }
  }
}
